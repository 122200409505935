<script>
import { ref } from '@vue/composition-api'
import IconPlatform from '@/components/IconPlatform.vue'
import { useMessage } from '@/hooks/useMessage'
import { removeWays } from '../api'

export default {
  name: 'Delete',
  components: { IconPlatform },
  props: {
    deleteRow: {
      type: Array,
      default: () => ([]),
    },
    row: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const visible = ref(true)
    const [start, end] = props.deleteRow
    const { message } = useMessage()
    function submit() {
      const params = {
        id: props.row.id,
        type: props.row.activeType,
      }

      removeWays(params).then(({ data }) => {
        if (data.status === 200) {
          message.success('删除成功')
          visible.value = false
          emit('refresh')
        } else {
          message.error(data.message)
        }
      }).catch(error => {
        message.error(error.response.data.message)
      })
    }

    return {
      visible,
      start,
      end,
      submit,
    }
  },
}
</script>

<template>
  <vl-dialog
    :visible.sync="visible"
    title="删除"
    width="450px"
    top="0"
    @close="$emit('close')"
  >
    <div class="flex-vertical">
      <div class="flex-left gap4 fs14">
        <v-icon color="#F24545" size="20">
          mdi-alert-circle
        </v-icon>
        发布通道删除后不可恢复，是否确认删除？
      </div>
      <div class="flex-between mt16 fs14 gap8">
        <div class="road-item flex-center gap4">
          <IconPlatform :platform="start.platform" />
          <span class="line-clamp1">{{ start.name }}</span>
        </div>
        <img src="@/assets/images/ways/road.png" height="20" alt="road" />
        <div class="road-item flex-center gap4">
          <IconPlatform :platform="end.platform" />
          <span class="line-clamp1">{{ end.name }}</span>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex-right gap8">
        <v-btn outlined @click="visible = false">
          取消
        </v-btn>
        <v-btn color="primary" @click="submit">
          确定
        </v-btn>
      </div>
    </template>
  </vl-dialog>
</template>

<style scoped lang="scss">
.road-item {
  padding: 0 12px;
  flex: auto;
  width: 100%;
  height: 52px;
  background: #F6F7F8;
  border-radius: 6px;
  color: rgba(0,0,0,0.8);
}

.vl-dialog__wrapper {
  display: flex;
  align-items: center;
}
</style>
