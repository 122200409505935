<script>
import { defineComponent, computed } from '@vue/composition-api'

export default defineComponent({
  props: {
    // 区分子频道，自带频道，默认都无
    type: {
      type: Object,
      default: () => {},
    },
    channels: {
      type: Array,
      default: () => [],
    },
    channel: {
      type: Object,
      default: () => ({
        channelName: '',
        link: '',
        platform: '',
      }),
    },

    // 自带频道
    selfChannel: {
      type: Object,
      default: () => ({
        channelName: '',
        link: '',
        platform: '',
      }),
    },
  },
  setup(props) {
    const isSelf = computed(() => props.type?.selfChannel)
    const isChild = computed(() => props.type?.subChannel)

    return {
      isSelf,
      isChild,
    }
  },
})
</script>

<template>
  <div class="link-box">
    <v-tooltip
      v-if="channels.length>1"
      top
    >
      <template
        #activator="{ on, attrs }"
      >
        <div
          v-bind="attrs"
          v-on="on"
        >
          <a
            :href="channel.link"
            target="_blank"
            class="link-text"
            :title="channel.channelName"
          >
            <XwPlatform
              v-if="channel.platform"
              :platform="channel.platform"
              :show-name="false"
            /><span>{{ channel.channelName || '-' }}</span>
          </a>
        </div>
      </template>
      <span>
        <a
          v-for="(item, index) in channels"
          :key="index"
          :href="item.link"
          target="_blank"
          class="link-self-text"
          :title="item.channelName"
        >
          <XwPlatform
            v-if="item.platform"
            :platform="item.platform"
            :show-name="false"
          /><span>{{ item.channelName || '-' }}</span>
        </a>
      </span>
    </v-tooltip>
    <a
      v-else-if="channel.link"
      :href="channel.link"
      target="_blank"
      class="link-text"
      :title="channel.channelName"
    >
      <XwPlatform
        v-if="channel.platform"
        :platform="channel.platform"
        :show-name="false"
      /><span>{{ channel.channelName || '-' }}</span>
    </a>
    <div
      v-else-if="!channel.link"
      class="link-default"
      :title="channel.channelName"
    >
      <XwPlatform
        v-if="channel.platform"
        :platform="channel.platform"
        :show-name="false"
      /><span>{{ channel.channelName || '-' }}</span>
    </div>
    <span
      v-if="isChild"
      class="link-child"
    >子</span>
    <v-tooltip
      v-if="isSelf"
      top
      :close-delay="1000"
    >
      <template
        #activator="{ on, attrs }"
      >
        <span
          class="link-self"
          v-bind="attrs"
          v-on.stop="on"
        >自带</span>
      </template>
      <div class="link-self-box">
        <a
          v-if="selfChannel.link"
          :href="selfChannel.link"
          target="_blank"
          class="link-self-text"
          :title="selfChannel.channelName"
        >
          <XwPlatform
            v-if="selfChannel.platform"
            :platform="selfChannel.platform"
            :show-name="false"
          />{{ selfChannel.channelName || '-' }}
        </a>
        <div
          v-else
          class="link-self-default"
          :title="selfChannel.channelName"
        >
          <XwPlatform
            v-if="selfChannel.platform"
            :platform="selfChannel.platform"
            :show-name="false"
          />{{ selfChannel.channelName || '-' }}
        </div>
      </div>
    </v-tooltip>
  </div>
</template>
<style lang="scss" scoped>
.link{
  &-box {
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &-text, &-default {
    display: flex;
    color: rgba(0,0,0,0.8);
    overflow: hidden;
    text-decoration: none;
    span{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 140px;
    }
  }
  &-text:hover{
    color: #2CA0F8;
  }
  &-child {
    font-size: 12px;
    color: #FFA80F;
    border: 1px solid #FFA80F;
    margin-left: 4px;
    border-radius: 4px;
    width: 18px;
    height: 18px;
    display: block;
    line-height: 16px;
    padding: 0 3px;
    white-space: nowrap;
  }
  &-self {
    font-size: 12px;
    color: #2CA0F8;
    margin-left: 4px;
    border-radius: 4px;
    width: 30px;
    height: 18px;
    padding: 0 3px;
    border: 1px solid #2CA0F8;
    line-height: 16px;
    display: block;
    white-space: nowrap;
  }
  &-self-box {
    display: flex;
    padding: 5px 10px;
    color: #fff;
  }
  &-self-text, &-self-default {
    color: #fff!important;
    display: flex;
    align-items: center;
    line-height: 20px;
    text-decoration: none;
  }
}
</style>
