import html2Canvas from 'html2canvas'
import jsPDF from 'jspdf'

async function drawPdf(option = { width: 210, height: 297 }, dom, pdf) {
  const element = dom || document.getElementById('pdfDom')
  window.scrollTo(0, 0)

  const canvas = await html2Canvas(element, {
    useCORS: true,
    allowTaint: false,
    taintTest: true,
    scale: 2,
    dpi: 500,
  })

  const contentWidth = canvas.width
  const contentHeight = canvas.height
  const pdfWidth = option.width
  const pdfHeight = option.height
  const pageHeight = (contentWidth / pdfWidth) * pdfHeight
  let leftHeight = contentHeight
  let position = 0

  const pageData = canvas.toDataURL('image/jpeg', 1.0)
  // eslint-disable-next-line new-cap
  pdf = pdf || new jsPDF({
    orientation: 'p',
    unit: 'mm',
    format: [option.width, option.height],
    rowPageBreak: 'avoid',
  })

  if (leftHeight < pageHeight) {
    // pdf.addImage(pageData, 'JPEG', 0, 0, pdfWidth, pdfHeight)
    pdf.addImage(pageData, 'JPEG', 0, 0, pdfWidth, pdfWidth / (contentWidth / contentHeight))
  } else {
    while (leftHeight > 0) {
      pdf.addImage(pageData, 'JPEG', 0, position, pdfWidth, (pdfWidth / contentWidth) * contentHeight)
      leftHeight -= pageHeight
      position -= pdfHeight
      if (leftHeight > 0) {
        pdf.addPage()
      }
    }
  }

  return pdf
}

// function handlePrint(url) {
//   const printWindow = window.open(url)
//   printWindow.onload = () => {
//     const style = `
//       @media print {
//         @page {
//           size: auto;
//           margin: 0;
//         }
//         body {
//           transform: scale(1);
//           transform-origin: top left;
//           width: 100%;
//           height: 100%;
//           overflow: hidden;
//         }
//         img {
//           width: 100%;
//           height: auto;
//         }
//       }
//     `
//     const styleSheet = printWindow.document.createElement('style')
//     styleSheet.type = 'text/css'
//     styleSheet.innerText = style
//     printWindow.document.head.appendChild(styleSheet)
//     printWindow.print()
//     printWindow.onafterprint = () => {
//       printWindow.close()
//       URL.revokeObjectURL(url) // 清理资源
//     }
//   }
// }

// const htmlToPdf = {
//   async exportPdf(option, dom, name) {
//     try {
//       let pdf = null
//       if (dom instanceof Array) {
//         for (let index = 0; index < dom.length; index++) {
//           pdf = await drawPdf(option, dom[index], pdf);
//           if (index < dom.length-1) {
//             pdf.addPage()
//           }
//         }
//       } else {
//         pdf = await drawPdf(option, dom)
//       }
//       pdf.save(`${name}.pdf`)
//     } catch (error) {
//       console.error('导出PDF失败', error)
//     }
//   },

//   async printPdf(option, dom) {
//     try {
//       let pdf = null;
//       if (dom instanceof Array) {
//         for (let index = 0; index < dom.length; index++) {
//           pdf = await drawPdf(option, dom[index], pdf)
//           if (index < dom.length-1) {
//             pdf.addPage()
//           }
//         }
//       } else {
//         pdf = await drawPdf(option, dom)
//       }
//       const pdfBlob = pdf.output('blob')
//       const url = URL.createObjectURL(pdfBlob)
//       handlePrint(url)
//     } catch (error) {
//       console.error('打印PDF失败', error)
//     }
//   },
// }

// export default htmlToPdf
export const exportPdf = async (option, dom, name) => {
  try {
    let pdf = null
    if (dom instanceof Array) {
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < dom.length; index++) {
        // eslint-disable-next-line no-await-in-loop
        pdf = await drawPdf(option, dom[index], pdf)
        if (index < dom.length - 1) {
          pdf.addPage()
        }
      }
    } else {
      pdf = await drawPdf(option, dom)
    }
    pdf.save(`${name}.pdf`)
  } catch (error) {
    console.error('导出PDF失败', error)
  }
}
