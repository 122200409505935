import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppView',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticStyle:{"width":"100%"}},[_c('XwTabs',{attrs:{"tabs":_vm.tabsConfig,"active":_vm.active,"is-loading":_vm.loading},on:{"change":_vm.handleTabChange}}),_c('div',{staticStyle:{"padding":"12px 0"}},[_c('XwSearch',{ref:"searchRef",attrs:{"form-config":_vm.searchConfig},on:{"search":_vm.search}})],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('new-pagination',{attrs:{"page-index":_vm.page,"page-size":_vm.pageSize,"total":_vm.pageLength},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})]},proxy:true}])},[(_vm.loading)?_c(VProgressLinear,{attrs:{"indeterminate":""}}):_vm._e(),_c('XwTable',{ref:"tableRef",attrs:{"columns":_vm.tableCols,"rows":_vm.pageList,"row-config":{height: 80},"height":"auto"},scopedSlots:_vm._u([{key:"video",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"video-box"},[_c('div',{staticClass:"video-img"},[_c(VImg,{attrs:{"src":row.img,"width":"105","height":"59"}}),(row.duration)?_c('div',{staticClass:"video-duration"},[_vm._v(" "+_vm._s(_vm.convertSecondsToHMS(row.duration))+" ")]):_vm._e()],1),_c('div',{staticClass:"video-info ml-2"},[_c('div',{staticClass:"video-title",attrs:{"title":row.title}},[_vm._v(" "+_vm._s(row.title)+" ")]),_c('div',{staticClass:"video-size"},[_vm._v(" "+_vm._s(row.videoId)+" ")])])])]}},{key:"channelName",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"video-channel"},[(row.platform)?_c('XwPlatform',{attrs:{"platform":row.platform,"show-name":false}}):_vm._e(),_vm._v(" "+_vm._s(row.channelName)+" ")],1)]}},{key:"time",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" 创建时间："+_vm._s(row.publishedAt || '-')),_c('br'),_vm._v(" 获取时间："+_vm._s(row.createdAt || '-')+" ")])]}},{key:"authorizeTime",fn:function(ref){
var row = ref.row;
return [(row.authorizeStart && row.authorizeEnd)?_c('div',[_vm._v(" "+_vm._s(_vm.dayjs(row.authorizeStart).format('YYYY.MM.DD'))+" - "+_vm._s(_vm.dayjs(row.authorizeEnd).format('YYYY.MM.DD'))+" ")]):_c('div',[_vm._v(" - ")])]}},{key:"registerPlatform",fn:function(ref){
var row = ref.row;
return [(row.registerPlatform && row.registerPlatform.length)?_c('div',{staticClass:"current-register"},[_c('div',_vm._l((row.registerPlatform),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(index < 3),expression:"index < 3"}],key:index},[_vm._v(" "+_vm._s(item)+" ")])}),0),(row.registerPlatform.length > 3)?_c('div',{staticClass:"more"},[_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text--link pl10"},'span',attrs,false),on),[_vm._v("更多")])]}}],null,true)},[_c('div',_vm._l((row.registerPlatform),function(item,idx){return _c('div',{key:idx},[_vm._v(" "+_vm._s(item)+" ")])}),0)])],1):_vm._e()]):_c('div',[_vm._v(" - ")])]}},{key:"opts",fn:function(ref){
var row = ref.row;
return [(_vm.active === 'channel')?_c('a',{on:{"click":function($event){return _vm.handleTimeline(row)}}},[_vm._v(" 时间线 ")]):_vm._e(),(_vm.active !== 'channel')?_c('a',{on:{"click":function($event){return _vm.handleSetting(row)}}},[_vm._v(" 设置 ")]):_vm._e()]}}])}),(_vm.showDialog)?_c('Audit',{attrs:{"active-item":_vm.activeItem,"curr-item":_vm.currItem},on:{"success":_vm.handleRefresh},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}}):_vm._e(),(_vm.showTimeline)?_c('Timeline',{attrs:{"curr-item":_vm.currItem,"type":"homePage"},model:{value:(_vm.showTimeline),callback:function ($$v) {_vm.showTimeline=$$v},expression:"showTimeline"}}):_vm._e(),(_vm.showSetting)?_c('Setting',{attrs:{"curr-item":_vm.currItem,"category-options":_vm.categoryOptions},on:{"success":_vm.handleRefresh},model:{value:(_vm.showSetting),callback:function ($$v) {_vm.showSetting=$$v},expression:"showSetting"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }