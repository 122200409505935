<script>
import {
  defineComponent, computed, ref,
} from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import { required } from '@core/utils/validation'
import { audit } from '../api'

export default defineComponent({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    currItem: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const showDialog = computed(() => props.value)
    const formRef = ref()
    const formData = ref({
      status: null, // 3通过 0拒绝
      auditRemark: null,
    })

    const radioMap = [
      {
        text: '通过',
        value: 3,
      },
      {
        text: '拒绝',
        value: 0,
      },
    ]

    const { message } = useMessage()

    const handleClose = () => {
      emit('input', false)
    }

    const handleSubmit = async () => {
      const valid = formRef.value.validate()
      if (!valid) return
      if (!formData.value.status && formData.value.status !== 0) {
        message.error(`审核操作，必选`)

        return
      }

      const { data: { status, message: messageText } } = await audit({
        id: props.currItem.id,
        ...formData.value,
      })

      if (status === 200) {
        message.success('审核成功')
        handleClose()
        emit('success')
      } else {
        message.error(messageText)
      }
    }

    const handerCollectionType = val => {
      formData.value.status = val
    }

    return {
      showDialog,
      formRef,
      required,
      formData,
      radioMap,
      handleClose,
      handleSubmit,
      handerCollectionType,
    }
  },
})
</script>

<template>
  <v-dialog
    v-model="showDialog"
    persistent
    width="400"
  >
    <v-card class="form-box">
      <h3 class="form-title">
        审核
        <v-btn
          icon
          small
          title="关闭"
          style="position: absolute; top: 16px; right: 20px;"
          @click="handleClose"
        >
          <v-icon size="22">
            mdi-close
          </v-icon>
        </v-btn>
      </h3>
      <v-form
        ref="formRef"
        class="current-info"
      >
        <div class="current-item">
          <label>审核</label>
          <v-btn
            small
            text
            depressed
            outlined
            @click="handerCollectionType(3)"
            :class="{'btn-actived':formData.status===3}"
          >
            通过
          </v-btn>
          <v-btn
            small
            class=" ml-2"
            text
            depressed
            outlined
            @click="handerCollectionType(0)"
            :class="{'btn-actived':formData.status===0}"
          >
            拒绝
          </v-btn>
          <!-- <v-radio-group
            v-model="formData.status"
            :rules="[required]"
            hide-details="true"
          >
            <v-radio
              v-for="item in radioMap"
              :key="item.value"
              :label="item.text"
              :value="item.value"
              :ripple="false"
            ></v-radio>
          </v-radio-group> -->
        </div>
        <div class="current-item">
          <label>备注</label>
          <div>
            <v-textarea
              v-model.trim="formData.auditRemark"
              placeholder=""
              style="width: 300px"
              hide-details="auto"
              dense
              outlined
              clearable
              maxlength="200"
              counter
              height="100"
              :rules="[formData.status === 0 ? required : () => true]"
              no-resize
            ></v-textarea>
          </div>
        </div>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          text
          depressed
          outlined
          @click="handleClose"
        >
          取消
        </v-btn>
        <v-btn
          color="primary"
          depressed
          @click="handleSubmit"
        >
          确认
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style lang="scss" scoped>
.form-box{
  padding: 20px 0 0 0;
  .form-title{
    margin: 0 24px;
    padding-bottom: 16px;
    font-size: 20px;
    color: #000;
    border-bottom: 1px solid #F2F3F5;
    span{
      font-size: 14px;
      color: rgba(0, 0, 0, 0.80);
      font-weight: normal;
    }
  }
  label{
    display: block;
    width: 44px;
    text-align: left;
    height: 34px;
    color: rgba(0, 0, 0, 0.85);
  }
  ::v-deep .v-card__actions .v-btn{
    min-width: 65px;
  }
  .current-info{
    padding: 20px 20px 0 20px;
    font-size: 14px;
    .current-item{
      display: flex;
      align-items: left;
      margin-bottom: 10px;
      ::v-deep .v-input--selection-controls{
        margin-top: -2px;
      }
      ::v-deep .v-input--radio-group__input{
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        .v-radio{
          margin-right: 20px;
        }
      }
      ::v-deep .v-input--selection-controls{
        margin-top: -2px;
      }
      ::v-deep .v-label{
        font-size: 14px;
      }
      ::v-deep .v-input--radio-group__input{
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .v-radio{
          margin-right: 20px;
          margin-bottom: 0;
        }
      }
      ::v-deep .v-input--selection-controls__input{
        width: 16px;
        height: 16px;
      }
      .radio-item{
        display: flex;
        align-items: center;
        label{
          width: 50px;
          text-align: left;
        }
      }
      .btn-actived.theme--light.v-btn.v-btn--outlined.v-btn--text{
        border: 1px solid #9155FD;
        color: #9155FD;
      }
    }

  }
  ::v-deep .v-textarea .v-text-field__details{
        position: relative;
        z-index: 2;
        margin-top: -20px;
      }
}
</style>
