<script>
import NoticeBox from '@/views/ways/publishWays/components/NoticeBox.vue'
import { nextTick, reactive, ref, watch } from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import { getUUID } from '@core/utils'
import { useValidation } from '@/hooks/useValidation'
import { getRegisterChannel, saveWays } from '@/views/ways/publishWays/api'
import { debounce } from '@prettyy/utils'
import Channel from './Channel.vue'
import Works from './Works.vue'

export default {
  name: 'AddData',
  components: { Channel, NoticeBox, Works },
  props: {
    platform: {
      type: Number,
      default: () => (0), // 0为youtube 1为facebook
    },
    activeType: {
      type: Number,
      default: () => (1), // 0为签约频道 1为签约作品
    },
  },
  setup(props, { emit }) {
    const visible = ref(true)
    const formRef = ref()
    const register = ref(false)
    const listContainer = ref()
    const { message } = useMessage()
    const isSingle = ref(false)
    const search = ref('')
    const errorList = ref([])
    const visibleError = ref(false)
    const normalList = ref([])
    const formData = reactive({
      channelId: null, // 注册id
      signList: [
        {
          _key_: getUUID(),
          signPlatform: undefined, // 签约平台
          copyrightHolder: undefined, // CP名称
          signChannelId: undefined, // 签约id
          signChannelName: undefined, // 签约名称
          signUrl: undefined, // 签约链接
          registerPlatform: props.platform, // 注册平台
          type: props.activeType,
          keyId: undefined,
        },
      ],
    })
    const channelList = ref([])
    const { required } = useValidation()

    function getRegisterChannelList(e) {
      if (channelList.value.find(item => item.text === e)) return
      register.value = true
      getRegisterChannel({ type: props.platform, channelId: e }).then(({ data }) => {
        if (data.status === 200) {
          register.value = false
          channelList.value = (data.data || []).map(item => ({
            ...item,
            text: `${item.channelName}(${item.id})`,
            value: item.id,
            type: item.collectionType, // 频道类型单开合集
          }))
          if (channelList.value.length === 0) message.warning('暂未查询到相关内容')
        }
      })
    }

    const searchQuery = debounce(500, getRegisterChannelList)

    watch(search, val => val && searchQuery(val))

    function submit() {
      formRef.value.asyncValidate().then(() => {
        errorList.value = []
        normalList.value = []
        const findData = channelList.value.find(item => item.value === formData.channelId)
        const params = {
          registerChannelId: formData.channelId,
          publishChannelVOList: formData.signList.map(item => ({
            ...item,
            registerChannelId: formData.channelId,
            registerChannelName: findData.channelName,
          })),
        }
        saveWays(params).then(({ data }) => {
          if (data.status === 200) {
            if (!data.data || data.data.length === 0) {
              message.success('新增成功')
              emit('refresh')
              visible.value = false

              return
            }
            normalList.value = params.publishChannelVOList.filter(item => data.data.some(value => value.signChannelId !== item.signChannelId))
            visibleError.value = true
            errorList.value = data.data
          } else {
            message.error(data.message)
          }
        })
      })
    }

    function channelChange(e) {
      const data = channelList.value.find(item => item.value === e)
      if (data.type === '单开') {
        isSingle.value = true
        formData.signList = formData.signList.splice(0, 1)

        return
      }
      isSingle.value = false
    }

    function refresh() {
      formData.signList = normalList.value
      submit()
    }

    function addSign() {
      if (formData.signList.length >= 20) {
        message.warning('最多添加20个签约频道')

        return false
      }
      formData.signList.push({
        signPlatform: undefined,
        copyrightHolder: undefined,
        signChannelId: undefined,
        signChannelName: undefined,
        signUrl: undefined,
        registerPlatform: props.platform,
        videoCustomTag: undefined, // 视频自定义标签
        playlist: undefined, // 播放列表
        keyId: undefined,
        type: props.activeType,
        _key_: getUUID(),
      })

      nextTick(() => {
        const container = listContainer.value
        container.goToBottom()
      })

      return true
    }

    return {
      visible,
      formRef,
      formData,
      required,
      channelList,
      search,
      register,
      listContainer,
      submit,
      channelChange,
      isSingle,
      visibleError,
      errorList,
      normalList,
      refresh,
      addSign,
    }
  },
}
</script>

<template>
  <vl-dialog
    :visible.sync="visible"
    :title="`新增${platform ? 'FB' : 'YT'}发布通道`"
    width="750px"
    top="0"
    @close="$emit('close')"
  >
    <vl-form ref="formRef">
      <div class="grid-container py8" style="background: #F6F7F8; border-radius: 4px;">
        <vl-form-item :label="`注册${ platform ? '主页' : '频道'}：`" required label-width="96">
          <v-autocomplete
            v-model="formData.channelId"
            :rules="[required]"
            :loading="register"
            hide-details
            :items="channelList"
            :search-input.sync="search"
            placeholder="请输入后选择"
            dense
            hide-no-data
            outlined
            clearable
            @change="channelChange"
          />
        </vl-form-item>
      </div>

      <Channel v-if="activeType === 0" ref="listContainer" v-model="formData.signList" />
      <Works v-else ref="listContainer" v-model="formData.signList" />

      <div v-if="!isSingle">
        <v-btn
          text
          width="110"
          color="primary"
          @click="addSign"
        >
          <v-icon size="20" class="mr4">
            mdi-plus-circle
          </v-icon>
          新增{{ !activeType ? '签约频道' : '签约作品' }}
        </v-btn>
      </div>
    </vl-form>

    <template #footer>
      <div class="flex-right gap8">
        <v-btn outlined @click="visible = false">
          取消
        </v-btn>
        <v-btn color="primary" @click="submit">
          确认
        </v-btn>
      </div>
    </template>

    <NoticeBox
      v-if="visibleError"
      :error-list="errorList"
      @close="visibleError = false"
      @refresh="refresh"
    />
  </vl-dialog>
</template>

<style scoped lang="scss">
.vl-dialog__wrapper {
  display: flex;
  align-items: center;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
}

::v-deep .vl-form-item {
  .vl-form-item-label {
    font-size: 14px !important;
  }
}

.vl-form-item + .vl-form-item {
  margin-top: 0;
}
::v-deep .vl-dialog__body {
  padding: 20px 0 !important;
}
</style>
