import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","width":"400"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,{staticClass:"form-box"},[_c('h3',{staticClass:"form-title"},[_vm._v(" 审核 "),_c(VBtn,{staticStyle:{"position":"absolute","top":"16px","right":"20px"},attrs:{"icon":"","small":"","title":"关闭"},on:{"click":_vm.handleClose}},[_c(VIcon,{attrs:{"size":"22"}},[_vm._v(" mdi-close ")])],1)],1),_c(VForm,{ref:"formRef",staticClass:"current-info"},[_c('div',{staticClass:"current-item"},[_c('label',[_vm._v("审核")]),_c(VBtn,{class:{'btn-actived':_vm.formData.status===3},attrs:{"small":"","text":"","depressed":"","outlined":""},on:{"click":function($event){return _vm.handerCollectionType(3)}}},[_vm._v(" 通过 ")]),_c(VBtn,{staticClass:" ml-2",class:{'btn-actived':_vm.formData.status===0},attrs:{"small":"","text":"","depressed":"","outlined":""},on:{"click":function($event){return _vm.handerCollectionType(0)}}},[_vm._v(" 拒绝 ")])],1),_c('div',{staticClass:"current-item"},[_c('label',[_vm._v("备注")]),_c('div',[_c(VTextarea,{staticStyle:{"width":"300px"},attrs:{"placeholder":"","hide-details":"auto","dense":"","outlined":"","clearable":"","maxlength":"200","counter":"","height":"100","rules":[_vm.formData.status === 0 ? _vm.required : function () { return true; }],"no-resize":""},model:{value:(_vm.formData.auditRemark),callback:function ($$v) {_vm.$set(_vm.formData, "auditRemark", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.auditRemark"}})],1)])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"secondary","text":"","depressed":"","outlined":""},on:{"click":_vm.handleClose}},[_vm._v(" 取消 ")]),_c(VBtn,{attrs:{"color":"primary","depressed":""},on:{"click":_vm.handleSubmit}},[_vm._v(" 确认 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }