import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","width":"430"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,{staticClass:"form-box"},[_c('h3',{staticClass:"form-title"},[_vm._v(" "+_vm._s(_vm.multiple ? '批量标记' : '提示')+" "),_c(VBtn,{staticStyle:{"position":"absolute","top":"16px","right":"20px"},attrs:{"icon":"","small":"","title":"关闭"},on:{"click":_vm.handleClose}},[_c(VIcon,{attrs:{"size":"22"}},[_vm._v(" mdi-close ")])],1)],1),_c(VForm,{ref:"formRef",staticClass:"current-info"},[(_vm.multiple)?_c('div',{staticClass:"mark-title"},[_vm._v(" 确定将以下 频道/作品 的"+_vm._s(_vm.isBanner ? 'Banner设计' : '频道设置')+"标记为已处理？ ")]):_vm._e(),(!_vm.multiple)?_c('div',{staticClass:"mark-title mb-5"},[_vm._v(" 确定将 "),_c('div',{staticClass:"mark-channel mr-2"},[_c('XwPlatform',{staticClass:"ml-1",attrs:{"platform":_vm.checkRows[0].authorizePlatform,"show-name":false}}),_c('span',{staticClass:"channel-name"},[_vm._v(_vm._s(_vm.checkRows[0].registerChannelName || _vm.checkRows[0].oldChannelName))])],1),_vm._v(" 的"+_vm._s(_vm.isBanner ? 'Banner设计' : '频道设置')+"标记为已处理？ ")]):_vm._e(),(_vm.multiple)?_c('div',{staticClass:"mark-channels"},_vm._l((_vm.checkRows),function(row){return _c('a',{key:row.id,staticClass:"mark-item text--link",attrs:{"href":"","target":"_blank"}},[_c('XwPlatform',{attrs:{"platform":row.authorizePlatform,"show-name":false}}),_vm._v(_vm._s(row.registerChannelName || row.oldChannelName))],1)}),0):_vm._e()]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"secondary","text":"","outlined":"","depressed":""},on:{"click":_vm.handleClose}},[_vm._v(" 取消 ")]),_c(VBtn,{attrs:{"color":"primary","depressed":""},on:{"click":_vm.handleSubmit}},[_vm._v(" 确认 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }