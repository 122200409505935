import dayjs from 'dayjs'

const tableCols = [
  {
    text: '视频',
    sortable: false,
    value: 'video',
    active: ['channel'],
    slots: 'video',
    minWidth: 350,
  },
  {
    text: '源频道',
    sortable: false,
    value: 'channelName',
    active: ['channel'],
    width: 150,
    slots: 'channelName',
  },
  {
    text: '时间',
    sortable: false,
    value: 'time',
    active: ['channel'],
    slots: 'time',
    width: 220,
  },
  {
    text: '作品名',
    sortable: false,
    value: 'name',
    active: ['video', 'image', 'audio'],
    fixed: 'left',
    minWidth: 150,
  },
  {
    text: 'CP名称',
    sortable: false,
    value: 'copyrightHolder',
    active: ['video'],
    fixed: 'left',
    minWidth: 150,
  },
  {
    text: '集数/个数/字数',
    sortable: false,
    value: 'specification',
    active: ['video', 'image', 'audio'],
    width: 120,
  },
  {
    text: '时长（分钟）',
    sortable: false,
    value: 'duration',
    active: ['video', 'audio'],
    algin: 'right',
    width: 120,
  },
  {
    text: '链接',
    sortable: false,
    value: 'link',
    width: 200,
    active: ['video', 'image', 'audio'],
  },
  {
    text: '垂类',
    sortable: false,
    value: 'categoryStr',
    width: 150,
  },
  {
    text: '授权区域(地区)',
    sortable: false,
    value: 'authorizeRegion',
    width: 200,
  },
  {
    text: '授权平台',
    sortable: false,
    value: 'authorizePlatformName',
    width: 150,
  },
  {
    text: '授权期限',
    sortable: false,
    value: 'authorizeTime',
    slots: 'authorizeTime',
    width: 200,
  },
  {
    text: '发布情况',
    sortable: false,
    value: 'registerPlatform',
    active: ['channel'],
    width: 300,
    slots: 'registerPlatform',
  },
  {
    text: '获取时间',
    sortable: false,
    value: 'createdAt',
    active: ['video', 'image', 'audio'],
    width: 150,
    formatter: ({ cellValue }) => (cellValue ? dayjs(cellValue).format('YYYY-MM-DD HH:mm:ss') : '-'),
  },
  {
    text: '操作',
    sortable: false,
    value: 'opts',
    slots: 'opts',
    width: 100,
    fixed: 'right',
  },
]

export default tableCols
