<script>
import {
  defineComponent, computed, ref,
} from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import { required } from '@core/utils/validation'
import { marks } from '../api'

export default defineComponent({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'banner',
    },
    checkRows: {
      type: Array,
      default: () => ([]),
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const showDialog = computed(() => props.value)
    const formRef = ref()
    const formData = ref({
      backendSettingFlag: null,
      bannerDesignFlag: null,
    })

    const { message } = useMessage()
    const isBanner = computed(() => props.type === 'banner')

    const handleClose = () => {
      emit('input', false)
    }

    const handleSave = async () => {
      // 设置标记类型
      if (isBanner.value) {
        formData.value.bannerDesignFlag = 1
      } else {
        formData.value.backendSettingFlag = 1
      }
      const { data: { status, message: messageText } } = await marks({
        ids: props.checkRows.map(x => x.id),
        ...formData.value,
      })

      if (status === 200) {
        message.success('标记成功')
        handleClose()
        emit('success')
      } else {
        message.error(messageText)
      }
    }

    const handleSubmit = async () => {
      const valid = formRef.value.validate()
      if (!valid) return
      handleSave()
    }

    return {
      showDialog,
      required,
      formRef,
      formData,
      isBanner,
      handleClose,
      handleSubmit,
    }
  },
})
</script>

<template>
  <v-dialog
    v-model="showDialog"
    persistent
    width="430"
  >
    <v-card class="form-box">
      <h3 class="form-title">
        {{ multiple ? '批量标记' : '提示' }}
        <v-btn
          icon
          small
          title="关闭"
          style="position: absolute; top: 16px; right: 20px;"
          @click="handleClose"
        >
          <v-icon size="22">
            mdi-close
          </v-icon>
        </v-btn>
      </h3>
      <v-form
        ref="formRef"
        class="current-info"
      >
        <div
          v-if="multiple"
          class="mark-title"
        >
          确定将以下 频道/作品 的{{ isBanner ? 'Banner设计' : '频道设置' }}标记为已处理？
        </div>
        <div
          v-if="!multiple"
          class="mark-title mb-5"
        >
          确定将 <div class="mark-channel mr-2">
            <XwPlatform
              :platform="checkRows[0].authorizePlatform"
              :show-name="false"
              class="ml-1"
            /><span class="channel-name">{{ checkRows[0].registerChannelName || checkRows[0].oldChannelName }}</span>
          </div> 的{{ isBanner ? 'Banner设计' : '频道设置' }}标记为已处理？
        </div>

        <div
          v-if="multiple"
          class="mark-channels"
        >
          <a
            v-for="row in checkRows"
            :key="row.id"
            class="mark-item text--link"
            href=""
            target="_blank"
          >
            <XwPlatform
              :platform="row.authorizePlatform"
              :show-name="false"
            />{{ row.registerChannelName || row.oldChannelName }}</a>
        </div>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          text
          outlined
          depressed
          @click="handleClose"
        >
          取消
        </v-btn>
        <v-btn
          color="primary"
          depressed
          @click="handleSubmit"
        >
          确认
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style lang="scss" scoped>
.form-box{
  padding: 20px 0 0 0;
  .form-title{
    margin: 0 24px;
    padding-bottom: 16px;
    font-size: 20px;
    color: #000;
    border-bottom: 1px solid #F2F3F5;
    span{
      font-size: 14px;
      color: rgba(0, 0, 0, 0.80);
      font-weight: normal;
    }
  }
  label{
    display: block;
    width: 94px;
    text-align: left;
    height: 34px;
    margin-top: 7px;
    color: rgba(0, 0, 0, 0.85);
  }
  ::v-deep .v-card__actions .v-btn{
    min-width: 65px;
  }
  .current-info{
    padding: 20px 20px 0 20px;
    font-size: 14px;
    .current-item{
      display: flex;
      align-items: left;
      margin-bottom: 10px;
    }
  }
  .mark{
    &-title{
      font-size: 14px;
      display: flex;
      align-items: center;
      color: rgba(0,0,0,0.85);
      flex-wrap: wrap;
      .mark-channel{
        display: flex;
      }
      .channel-name{
        overflow: hidden;
        max-width: 110px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    &-channels{
      padding: 4px 0;
      display: flex;
      flex-direction: column;
    }
    &-item{
      margin: 4px 0;
      padding: 7px 8px;
      border-radius: 4px;
      background: #F6F7F8;
      height: 30px;
      line-height: 30px;
      display: flex;
      align-items: center;
      width: fit-content;
    }

  }
  .text--link{
    text-decoration: none;
    color: #000!important;
  }
  .text--link:hover{
    text-decoration: none;
    color: #2ca0f8!important;
  }
}
</style>
