import {
  computed, reactive, ref, onMounted,
} from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import {
  domPlatformOptions, publishStatusOptions,
} from '@core/utils/options'
import { getCategory } from '@/views/audit/clueAudit/api'
import { getPage } from './api'

export default function useSearch() {
  const loading = ref(false)
  const pageList = ref([])
  const page = ref(1)
  const pageSize = ref(20)
  const pageLength = ref(0)
  const active = ref('channel')
  const auditCountParams = ref({})
  const activeItem = ref({
    key: 'channel',
  })
  const searchRef = ref()
  const categoryOptions = ref([])

  const { message } = useMessage()

  // 查询初始化参数 todo
  const queryDataOri = {
    title: null,
    channelName: null,
    platform: null,
    tag: null,
    registerPlatform: null,
    name: null,
    categoryId: null,
    videoCreatedAtLeft: null,
    videoCreatedAtRight: null,
    timeRight: null,
    timeLeft: null,
  }

  // 查询参数增加响应
  const queryData = reactive({ ...queryDataOri })

  const searchConfig = computed(() => [
    {
      element: 'VTextField',
      props: 'title',
      placeholder: '视频标题',
      label: '视频标题',
      active: ['channel'],
    },
    {
      element: 'VTextField',
      props: 'channelName',
      placeholder: '源频道',
      label: '源频道',
      active: ['channel'],
    },
    {
      element: 'VAutocomplete',
      props: 'platform',
      placeholder: '源平台',
      label: '源平台',
      items: domPlatformOptions,
      multiple: false,
      active: ['channel'],
      itemText: 'label',
      itemValue: 'value',
    },
    {
      element: 'DateRangePickers',
      props: 'createdAt',
      searchKey: ['videoCreatedAtLeft', 'videoCreatedAtRight'],
      initValue: [null, null],
      placeholder: '视频创建时间',
      label: '视频创建时间',
      clearable: true,
      range: true,
      active: ['channel'],
    },
    {
      element: 'VTextField',
      props: 'name',
      placeholder: '作品名',
      label: '作品名',
      active: ['video', 'image', 'audio'],
    },
    {
      element: 'VCascader',
      props: 'categoryId',
      placeholder: '垂类',
      label: '垂类',
      items: categoryOptions.value,
      multiple: false,
      itemText: 'name',
      itemValue: 'id',
    },
    {
      element: 'VAutocomplete',
      props: 'registerPlatform',
      placeholder: '发布情况',
      label: '发布情况',
      items: publishStatusOptions,
      multiple: false,
      active: ['channel'],
      itemText: 'text',
      itemValue: 'value',
    },
    {
      element: 'DateRangePickers',
      props: 'createdAt',
      searchKey: ['timeLeft', 'timeRight'],
      initValue: [null, null],
      placeholder: '获取时间',
      label: '获取时间',
      clearable: true,
      range: true,
      active: ['video', 'image', 'audio'],
    },
  ])

  // 获取垂类
  const fetchCategory = async () => {
    const {
      data: { data = [], status, message: messageText },
    } = await getCategory()
    if (status === 200) {
      categoryOptions.value = data || []
    } else {
      message.error(messageText)
    }
  }

  const fetchList = async () => {
    loading.value = true
    pageList.value = []
    const {
      data: {
        data = [], status, total, message: messageText,
      },
    } = await getPage({
      page: page.value,
      pageSize: pageSize.value,
      activeItem: activeItem.value,
      ...queryData,
    })
    loading.value = false
    if (status === 200) {
      pageList.value = data
      pageLength.value = total
    } else {
      message.error(messageText)
    }
  }

  const search = async params => {
    const { isOpen, ...others } = params
    page.value = 1
    Object.assign(queryData, others)

    fetchList()
  }

  const handleRefresh = () => {
    fetchList()
  }

  const handleSizeChange = val => {
    page.value = 1
    pageSize.value = val
    fetchList()
  }
  const handleCurrentChange = val => {
    page.value = val
    fetchList()
  }

  const handleTabChange = tabItem => {
    activeItem.value = tabItem
    active.value = tabItem.key
    page.value = 1

    searchRef.value.reset()
  }

  onMounted(() => {
    fetchList()
    fetchCategory()
  })

  return {
    searchConfig,
    queryData,
    queryDataOri,
    loading,
    pageList,
    page,
    pageSize,
    pageLength,
    auditCountParams,
    active,
    activeItem,
    searchRef,
    categoryOptions,

    search,
    fetchList,
    handleRefresh,
    handleSizeChange,
    handleCurrentChange,
    handleTabChange,
  }
}
