<script>
import { computed } from '@vue/composition-api'

export default {
  name: 'IconPlatform',
  props: {
    platform: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const paltformEnum = {
      bilibili: ['B站', 'bilibili', 'b站'],
      iqiyi: ['爱奇艺', 'iqiyi'],
      haokan: ['好看', 'haokan'],
      douyin: ['抖音', 'douyin'],
      xigua: ['西瓜', 'xigua', 'ixigua'],
      kuaishou: ['快手', 'kuaishou'],
      xiaohongshu: ['小红书', 'xiaohongshu'],
      youtube: ['Youtube', 'youtube'],
      facebook: ['Facebook', 'facebook'],
    }

    const imgName = computed(() => props.platform && Object.keys(paltformEnum).find(key => paltformEnum[key].includes(props.platform.toLowerCase())))
    // eslint-disable-next-line import/no-dynamic-require
    const imgSrc = computed(() => (imgName.value ? require(`@/assets/images/logos/${imgName.value}.png`) : ''))

    return {
      imgSrc,
      imgName,
    }
  },
}
</script>

<template>
  <img v-if="imgSrc" :src="imgSrc" class="mr5" alt="icon">
</template>

<style scoped lang="scss">

</style>
