<script>
import { ref, reactive, nextTick, watch } from '@vue/composition-api'
import { VPopover } from 'v-tooltip'

export default {
  name: 'AppTooltip',
  components: { VPopover },
  props: {
    label: {
      type: String,
      default: '',
      required: true,
    },
    link: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '_blank',
    },
    isLink: Boolean,
    color: {
      type: String,
      default: '',
    },
    height: {
      type: [Number, String],
      default: 0,
    },
    width: {
      type: [Number, String],
      default: 0,
    },
    baseParent: Boolean,
  },
  setup(props) {
    const linkRef = ref()
    const isPopover = ref(false)
    const ellipsis = reactive({
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
      height: '100%',
      lineHeight: 'inherit',
    })

    const style = ref({
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '10px',
      height: '1px',
    })

    function init() {
      const parentElement = linkRef.value?.parentNode
      const { width, height } = props.baseParent ? parentElement.getBoundingClientRect() : {}
      ellipsis.width = `${(width || props.width) - 10}px`
      ellipsis.height = `${(height || props.height) - 4}px`
      ellipsis.lineHeight = `${(height || props.height)}px`
      if (props.color) {
        ellipsis.color = `${props.color} !important`
      }
      const computedStyle = window.getComputedStyle(parentElement)

      style.value = ellipsis
      const dicStyle = {
        height: '1px',
        fontSize: computedStyle.fontSize,
        lineHeight: 'inherit',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        backgroundColor: 'transparent',
        color: 'transparent',
        display: 'inline-block',
      }

      // 创建计算节点
      const element = document.createElement('div')
      const keys = Object.keys(dicStyle)
      keys.forEach(key => {
        element.style[key] = dicStyle[key]
      })
      element.innerText = props.label || props.link
      document.body.appendChild(element)

      // 子节点宽度如果大于父节点宽度，则显示弹出层
      if (element.scrollWidth > ((width || props.width) - 10)) {
        isPopover.value = true
      }

      // 移除计算节点
      document.body.removeChild(element)
    }

    watch(() => props.label, () => {
      nextTick(() => {
        init()
      })
    }, { immediate: true })

    return {
      linkRef,
      style,
      isPopover,
    }
  },
}
</script>
<template>
  <div
    v-if="!isPopover"
    ref="linkRef"
    class="text--primary text-truncate"
    :class="{ 'app-link': isLink }"
    :style="style"
  >
    <slot name="label">
      {{ label || link }}
    </slot>
  </div>
  <v-popover
    v-else
    :html="false"
    placement="bottom"
    popover-class="popover-content"
    trigger="hover"
    container="main"
  >
    <div
      class="text--primary text-truncate pointer"
      :class="{ 'app-link': isLink }"
      :style="style"
    >
      {{ label || link }}
    </div>
    <template #popover>
      <slot name="content">
        <span :style="{ color: color }" style="word-break: break-all;">
          {{ label || link }}
        </span>
      </slot>
    </template>
  </v-popover>
</template>

<style lang='scss' scoped>
.app-link {
  color: #2381FF !important;
  cursor: pointer;
  display: inline-block;
}
</style>
