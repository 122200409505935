<script>
import {
  defineComponent,
} from '@vue/composition-api'
import { resolvePrivacy } from '@core/utils/resolve'
import { useMessage } from '@/hooks/useMessage'
import { convertSecondsToHMS } from '@core/utils'
import tableCols from './tableCols'
import useSearch from './useSearch'
import { getExport } from './api'

export default defineComponent({
  components: {
  },
  setup() {
    const { message } = useMessage()
    const {
      queryData, pageList, pageLength, ...searchOthers
    } = useSearch()

    const handleExport = async () => {
      if (pageLength.value <= 0) {
        message.error('您没有可导出的内容')

        return
      }

      if (pageLength.value >= 10000) {
        message.error('请缩小筛选范围，系统只允许导出10000条数据')

        return
      }
      message.warning('报表导出中！')

      const { data } = await getExport({ ...queryData })
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' })
      const url = window.URL.createObjectURL(blob) // 创建一个临时的url指向blob对象
      const a = document.createElement('a')
      a.href = url
      a.download = `在 ${new Date().toLocaleString().replaceAll('/', '-')} 导出视频异常报表`
      a.click()
      window.URL.revokeObjectURL(url)
      message.success('导出完成！')
    }

    return {
      ...searchOthers,
      tableCols,
      resolvePrivacy,
      pageList,
      pageLength,
      convertSecondsToHMS,
      handleExport,
    }
  },
})
</script>

<template>
  <AppView>
    <template #header>
      <div style="width: 100%">
        <!--搜索区域-->
        <XwSearch
          ref="searchRef"
          :form-config="searchConfig"
          @search="search"
        />
      </div>
    </template>

    <template #main-header>
      <!--按钮区域-->
      <div style="padding: 6px 0">
        <v-btn
          outlined
          depressed
          @click="handleExport"
        >
          导出
        </v-btn>
      </div>
    </template>

    <v-progress-linear
      v-if="loading"
      indeterminate
    />
    <XwTable
      ref="tableRef"
      :columns="tableCols"
      :rows="pageList"
      :row-config="{height: 100}"
      height="auto"
    >
      <template #video="{row}">
        <div
          class="video-box"
        >
          <div class="video-info ml-2">
            <div class="video-title">
              {{ row.title }}
            </div>
            <div class="video-size">
              {{ row.videoId }}
            </div>
            <div
              v-if="row.duration"
              class="video-duration"
            >
              {{ convertSecondsToHMS(row.duration) }}
            </div>
          </div>
        </div>
      </template>
      <template #privacy="{row}">
        <div>{{ resolvePrivacy(row) || '-' }}</div>
      </template>
      <template #dateTime="{row}">
        <div>
          发布时间：{{ row.timePublishedSeconds || '-' }}<br />
          获取时间：{{ row.createdAt || '-' }}<br />
        </div>
      </template>
    </XwTable>

    <template #footer>
      <new-pagination
        :page-index="page"
        :page-size="pageSize"
        :total="pageLength"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </template>
  </AppView>
</template>
<style lang="scss" scoped>
.video{
    &-box{
      text-decoration: none;
    }
    &-preview{
      height: 60px;
      height: 105px;
      border-radius: 6px;
      overflow: hidden;
      position: relative;
    }
    &-duration{
      min-width: 40px;
      height: 20px;
      text-align: left;
      line-height: 20px;
      font-size: 12px;
      z-index: 1;
      color: #000;
    }
    &-info{
      line-height: 20px;
    }
    &-title{
      height: 40px;
      line-height: 20px;
      color: rgba(0,0,0,0.88);
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      width: 200px;
    }
    &-size{
      color: rgba(0,0,0,0.4);
    }
    &-img{
      width: 105px;
      height: 59px;
      background-color: #ddd;
      border-radius: 6px;
      overflow: hidden;
      position: relative;
    }
  }
</style>
