<script>
import { VPopover } from 'v-tooltip'

export default {
  name: 'TooltipBasic',
  components: { VPopover },
}
</script>

<template>
  <v-popover
    v-bind="{ trigger: 'hover', ...$attrs }"
    popover-class="popover-content"
    container="main"
  >
    <slot>
      <v-icon>
        mdi-information-outline
      </v-icon>
    </slot>
    <template #popover>
      <slot name="content">
        内容
      </slot>
    </template>
  </v-popover>
</template>
