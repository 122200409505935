<script>
import {
  defineComponent, provide, ref,
} from '@vue/composition-api'
import { useRouter } from '@core/utils'
import store from '@/store'
import Preview from './components/Preview.vue'
import Audit from './components/Audit.vue'

export default defineComponent({
  components: {
    Preview,
    Audit,
  },
  beforeRouteEnter(to, form, next) {
    store.commit('app/SET_PARENT_APP_TITLE', '领用注册')
    next()
  },
  beforeRouteLeave(to, form, next) {
    store.commit('app/SET_PARENT_APP_TITLE', null)
    next()
  },
  setup() {
    const { router } = useRouter()
    const { id , status } = router.currentRoute.query
    provide('currId', id)
    const currItem = ref({id})
    const showAudit = ref(false)
    const AuditIs = ref(status)

    const handleCancle = () => {
      router.go(-1)
    }
    const handleAudit = () => {
      showAudit.value = true
      // currItem.value = row
      console.log(currItem.value)
    }

    return {
      handleCancle,

      currItem,
      showAudit,
      AuditIs,

      handleAudit,
    }
  },
})
</script>

<template>
  <v-card
    elevation="0"
    class="form-box"
  >
    <Preview />
    <div class="form-btns">
      <v-btn
        outlined
        depressed
        @click="handleCancle"
      >
        {{ AuditIs?'取消': '关闭'}}
      </v-btn>
      <v-btn
        color="primary"
        class="ml-2"
        @click="handleAudit"
        v-show="AuditIs"
      >
        审核
      </v-btn>
    </div>
    <Audit
      v-if="showAudit"
      v-model="showAudit"
      :curr-item="currItem"
      @success="handleCancle"
    ></Audit>
  </v-card>
</template>
<style lang="scss" scoped>
.form{
  &-box{
    background-color: #fff;
    border-radius: 6px;
    margin-bottom: 70px;
  };
  &-btns{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 14px 20px 14px 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
  }
}
</style>
