<script>
import TooltipBasic from '@/components/TooltipBasic.vue'
import IconPlatform from '@/components/IconPlatform.vue'

export default {
  name: 'ToolsBox',
  components: { IconPlatform, TooltipBasic },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    platform: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    channelId: {
      type: String,
      default: '',
    },
  },
  methods: {
    copyText(text, dom) {
      const input = dom || document.createElement('input')
      if (navigator.clipboard) {
        navigator.clipboard.writeText(text)
        this.$message.success('复制成功！')
      } else {
        input.value = text
        if (!dom) document.body.appendChild(input)
        input.select()
        document.execCommand('copy')
        if (window.getSelection) window.getSelection().removeAllRanges()
        else if (document.selection) document.selection.empty()
        input.blur()
        if (!dom) document.body.removeChild(input)
        this.$message.success('复制成功！')
      }
    },
  },
}
</script>

<template>
  <TooltipBasic placement="top" :delay="500">
    <div class="flex-left pointer gap2">
      <IconPlatform :platform="platform" />
      <span>{{ name }}</span>
    </div>

    <template #content>
      <div>
        <span>频道名称：</span>
        <span>{{ name }}</span>
      </div>
      <div class="flex-left">
        <span>频道ID：</span>
        <span class="flex-left gap6">
          {{ channelId }}
          <span class="copy flex-center" @click="copyText(channelId)">
            <v-icon color="#fff" size="14">mdi-content-copy</v-icon>
          </span>
        </span>
      </div>
    </template>
  </TooltipBasic>
</template>

<style lang="scss" scoped>
.copy {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #4a4a4a;
  color: #fff;
  cursor: pointer;
}
</style>
