import { can } from '@core/utils/useAccess'

const tableCols = [
  {
    text: '',
    type: 'checkbox',
    value: 'check',
    width: 50,
    fixed: 'left',
  },
  {
    text: '申请单编号',
    sortable: false,
    value: 'applicationNo',
    fixed: 'left',
    width: 150,
  },
  {
    text: '签约频道/作品名',
    sortable: false,
    value: 'channelName',
    slots: 'channelName',
    width: 220,
  },
  {
    text: '申请类型',
    sortable: false,
    value: 'registerType',
    // width: 150,
    // formatter: ({ cellValue }) => (registerTypeOptions.find(row => row.value === cellValue) || {}).text || '-',
  },
  {
    text: '运营类型',
    sortable: false,
    value: 'operationMode',
    // width: 150,

    // formatter: ({ cellValue }) => (operationModeOptions.find(row => row.value === cellValue) || {}).text || '-',
  },
  {
    text: '申请单状态',
    sortable: false,
    value: 'status',
    // width: 150,
    align: 'left',
    slots: 'status',
  },
  {
    text: '关联频道/主页',
    value: 'registerChannel',
    width: 150,
    slots: 'registerChannel',
  },
  {
    text: '组别',
    sortable: false,
    value: 'operationGroupName',
    width: 150,
    showOverflow: true,
  },
  {
    text: '组长',
    sortable: false,
    value: 'operationLeaderName',
  },
  {
    text: '组员',
    sortable: false,
    value: 'operatingMemberName',
  },
  {
    text: '领用人',
    sortable: false,
    value: 'applicantName',
  },
  {
    text: '创建时间',
    sortable: false,
    value: 'createdAt',
    width: 170,
  },
  // {
  //   text: '频道后台设置',
  //   sortable: false,
  //   value: 'backendSettingFlag',
  //   width: 150,
  // },
  {
    text: '标记记录', // Banner设计 bannerDesignFlag 频道后台设置 backendSettingFlag
    sortable: false,
    value: 'bannerDesignFlag',
    width: 170,
    slots: 'bannerDesignFlag',
  },
  {
    text: '操作',
    sortable: false,
    value: 'opts',
    slots: 'opts',
    minWidth: can(['超级管理员', '研发管理员', '总编室管理员']) ? 250 : 150,
    fixed: 'right',
  },
]

export default tableCols
