import { isEmpty, isMobile, isEmail, isIdCard, isExternal } from '@prettyy/utils'

export function useValidation() {
  // 整数判断
  function integerValidator(value) {
    if (isEmpty(value)) {
      return true
    }

    if (Array.isArray(value)) {
      return value.every(val => /^-?[0-9]+$/.test(String(val)))
    }

    return /^-?[0-9]+$/.test(String(value)) || '必须是整数'
  }

  // 必填校验
  function required(value) {
    if (isEmpty(value)) return '这是必填项'

    return true
  }

  // 是否是手机号
  function isPhone(value) {
    if (isEmpty(value)) return true

    return isMobile(value) || '手机号码格式错误'
  }

  // 是否是邮箱
  function isMailbox(value) {
    if (isEmpty(value)) return true

    return isEmail(value) || '邮箱格式错误'
  }

  // 是否身份证
  function isIdCardNo(value) {
    if (isEmpty(value)) return true

    return isIdCard(value) || '身份证号码格式错误'
  }

  // 禁止输入特殊字符
  function specialCharacter(value) {
    if (isEmpty(value)) return true

    return /^[a-zA-Z0-9\u4e00-\u9fa5\s/()（）]+$/.test(value) || '不能输入特殊字符'
  }

  // 禁止输入超过小数点后两位
  function noMoreThanTwoDecimal(value) {
    if (isEmpty(value)) return true

    return /^\d+(\.\d{1,2})?$/.test(value) || '最多输入两位小数'
  }

  // 是否有效链接
  function validUrl(value) {
    if (isEmpty(value)) return true

    return isExternal(value) || '请输入有效的链接'
  }

  // 只允许输入英文字母和数字
  function onlyEnglishAndNumber(value) {
    if (isEmpty(value)) return true

    return /^[a-zA-Z0-9]+$/.test(value) || '只能输入英文字母和数字'
  }

  // 只允许输入正整数
  function onlyPositiveInteger(value) {
    if (isEmpty(value)) return true

    return /^[1-9]\d*$/.test(value) || '只能输入正整数'
  }

  // 校验手机号或者座机号
  function isPhoneOrTel(value) {
    if (isEmpty(value)) return true

    return /^(0\d{2,3}-\d{7,8})|(1[3456789]\d{9})$/.test(value) || '手机号码或座机号码格式错误'
  }

  return {
    integerValidator,
    required,
    isPhone,
    isMailbox,
    isIdCardNo,
    specialCharacter,
    noMoreThanTwoDecimal,
    validUrl,
    onlyEnglishAndNumber,
    onlyPositiveInteger,
    isPhoneOrTel,
  }
}
