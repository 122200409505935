import { VBtn } from 'vuetify/lib/components/VBtn';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppView',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticStyle:{"width":"100%"}},[_c('XwSearch',{ref:"searchRef",attrs:{"form-config":_vm.searchConfig},on:{"search":_vm.search}})],1)]},proxy:true},{key:"main-header",fn:function(){return [_c('div',{staticStyle:{"padding":"6px 0"}},[_c(VBtn,{attrs:{"outlined":"","depressed":""},on:{"click":_vm.handleExport}},[_vm._v(" 导出 ")])],1)]},proxy:true},{key:"footer",fn:function(){return [_c('new-pagination',{attrs:{"page-index":_vm.page,"page-size":_vm.pageSize,"total":_vm.pageLength},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})]},proxy:true}])},[(_vm.loading)?_c(VProgressLinear,{attrs:{"indeterminate":""}}):_vm._e(),_c('XwTable',{ref:"tableRef",attrs:{"columns":_vm.tableCols,"rows":_vm.pageList,"row-config":{height: 100},"height":"auto"},scopedSlots:_vm._u([{key:"video",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"video-box"},[_c('div',{staticClass:"video-info ml-2"},[_c('div',{staticClass:"video-title"},[_vm._v(" "+_vm._s(row.title)+" ")]),_c('div',{staticClass:"video-size"},[_vm._v(" "+_vm._s(row.videoId)+" ")]),(row.duration)?_c('div',{staticClass:"video-duration"},[_vm._v(" "+_vm._s(_vm.convertSecondsToHMS(row.duration))+" ")]):_vm._e()])])]}},{key:"privacy",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(_vm.resolvePrivacy(row) || '-'))])]}},{key:"dateTime",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" 发布时间："+_vm._s(row.timePublishedSeconds || '-')),_c('br'),_vm._v(" 获取时间："+_vm._s(row.createdAt || '-')),_c('br')])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }