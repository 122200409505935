<script>
import {
  defineComponent, reactive, ref,
} from '@vue/composition-api'
import Timeline from '@/views/account/homePage/components/Timeline/Timeline.vue'
import dayjs from 'dayjs'
import Platform from '@/components/platform/Platform.vue'
import Setting from './components/Setting.vue'
import tableCols from './tableCols'
import useSearch from './useSearch'

export default defineComponent({
  components: {
    Timeline,
    Platform,
    Setting,
  },
  setup() {
    const showTimeline = ref(false)
    const showSetting = ref(false)
    const currItem = ref({})
    const searchOthers = useSearch()

    const handleTimeline = row => {
      showTimeline.value = true
      currItem.value = row
    }

    const handleSetting = row => {
      showSetting.value = true
      currItem.value = row
    }

    return {
      showTimeline,
      currItem,
      ...searchOthers,
      tableCols: reactive(tableCols),
      dayjs,
      showSetting,
      handleTimeline,
      handleSetting,
    }
  },
})
</script>

<template>
  <AppView>
    <template #header>
      <div style="width: 100%">
        <!--搜索区域-->
        <XwSearch
          ref="searchRef"
          :form-config="searchConfig"
          @search="search"
        />
      </div>
    </template>

    <template #main-header>
      <div style="padding: 6px 0">
        <v-btn
          outlined
          depressed
          @click="handleExport"
        >
          导出
        </v-btn>
      </div>
    </template>

    <v-progress-linear
      v-if="loading"
      indeterminate
    />
    <XwTable
      ref="tableRef"
      :columns="tableCols"
      :rows="pageList"
      height="auto"
    >
      <template #channelName="{row}">
        <div class="overflow">
          <a
            :href="row.link"
            target="_blank"
            class="text--link"
            style="text-decoration: none"
          >{{ row.channelName || '-' }}</a>
        </div>
      </template>
      <template #platform="{row}">
        <Platform
          :platform="row.platform"
        />
      </template>
      <template #authorizePeriod="{row}">
        <div>
          {{ row.authorizeStart && dayjs(row.authorizeStart).format('YYYY.MM.DD') }} - {{ row.authorizeEnd && dayjs(row.authorizeEnd).format('YYYY.MM.DD') }}
        </div>
      </template>
      <template #ways="{row}">
        <div
          v-if="row.currentChannels && row.currentChannels.length"
          class="current-channel"
        >
          <div>
            <div
              v-for="(item, idx) in row.currentChannels"
              v-show="idx < 3"
              :key="idx"
            >
              {{ item }}
            </div>
          </div>
          <div
            v-if="row.currentChannels.length > 3"
            class="more"
          >
            <v-tooltip right>
              <template
                #activator="{ on, attrs }"
              >
                <span
                  v-bind="attrs"
                  class="text--link pl10"
                  v-on="on"
                >更多</span>
              </template>
              <div>
                <div
                  v-for="(item, idx) in row.currentChannels"
                  :key="idx"
                >
                  {{ item }}
                </div>
              </div>
            </v-tooltip>
          </div>
        </div>
        <div v-else>
          -
        </div>
      </template>
      <template #opts="{row}">
        <a
          class="mr-2"
          @click="handleSetting(row)"
        >
          设置
        </a>
        <a
          @click="handleTimeline(row)"
        >
          时间线
        </a>
      </template>
    </XwTable>

    <template #footer>
      <new-pagination
        :page-index="page"
        :page-size="pageSize"
        :total="pageLength"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </template>
    <!--时间轴-->
    <Timeline
      v-if="showTimeline"
      v-model="showTimeline"
      :curr-item="{...currItem, id: currItem.id}"
      type="signChannel"
    ></Timeline>
    <!--设置垂类-->
    <Setting
      v-if="showSetting"
      v-model="showSetting"
      :curr-item="currItem"
      :category-options="categoryOptions"
      @success="handleRefresh"
    ></Setting>
  </appview>
</template>
<style lang="scss" scoped>
.current-channel{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
</style>
